<template>
  <div :class="'teaser-nav '+classNav">
    <router-link :to="'/work/project/'+project.nid">
    <span class="img-container">
      <!--<img :src="fakeImg">-->
      <span  :style="`background-image: url(${project.thumb})`"></span>
    </span>
      <span class="teaser-text">
        <h3>{{caption}}</h3>
        <h1>
          {{project.title}}
        </h1>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
    name: "ProjectTeaserNav",
    props: {
        project: {required: true},
        right: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            fakeImg: require('../assets/visual.png')
        }
    },
    computed: {
        caption () {
            return this.right ? 'Prossimo' : 'Precedente'
        },
        classNav () {
            return this.right ? 'next-nav' : 'prev-nav'
        }
    }
}
</script>

<style scoped>

</style>