<template>
  <div class="section-container section-2" v-if="data">


    <div class="img-sm-grid">
      <image-display :data="data" :index="0"></image-display>
    </div>
    <div class="image-lg-grid">
      <image-container :data="data" :index="0"></image-container>
    </div>

    <section-text :data="data"></section-text>


  </div>
</template>

<script>


export default {
    name: "Section2",

    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            fakeImg: require('../../assets/visual.png')
        }
    }
}
</script>

<style scoped>

</style>