<template>
  <section id="homepage">
  </section>
</template>

<script>
export default {
    name: "Homepage"
}
</script>

<style scoped>

</style>