// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

    // WORK PAGE
    get_is_work_page (state) {
        return state.isWorkPage
    },
    get_current_term_id (state) {
        return state.currentTermId
    },
    // CLIENTS
    get_clients (state) {
        //console.log( 'getter get_backgrounds', state);
        return state.clients
    },
    // LOGOS
    get_logos (state) {
        //console.log( 'getter get_backgrounds', state);
        return state.logos
    },
    // SELECTED
    get_selected (state) {
        //console.log( 'getter get_backgrounds', state);
        return state.selected
    },

    // BAKCGROUND
    get_backgrounds (state) {
        //console.log( 'getter get_backgrounds', state);
        return state.backgrounds
    },
    get_background_data (state, getters) {
        const backgrounds = getters.get_backgrounds
        const currentBackgroundId = getters.get_current_background_id
        //console.log('get_background_data', backgrounds)
        //console.log('get_background_data', currentBackgroundId)
        if (backgrounds) {
            return backgrounds.find(background => background.nid === currentBackgroundId)
        } else {
            return false
        }
    },
    get_background_index (state, getters) {
        const backgrounds = getters.get_backgrounds
        const currentBackgroundId = getters.get_current_background_id
        if (backgrounds) {
            return backgrounds.findIndex(background => background.nid === currentBackgroundId)
        } else {
            return false
        }
    },
    get_current_background_id (state) {
        return state.currentBackgroundId
    },
    shouldBgUpdate (state) {
        const lastFetch = state.lastBgFetch
        if (!lastFetch) {
            return true
        }
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - lastFetch) / 1000 > 60
    },
    get_background_is_active (state) {
        return state.isBackgroundActive
    },
    get_is_transparent (state) {
        return state.isTransparent
    },
    get_background_is_loading (state) {
        return state.isBgLoading
    },


    // COMPONENT
    // amlexa-autosuggest
    // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),

    get_projects (state) {
        //console.( 'getter GET_ENTITY', state);
        return state.projects
    },
    get_filtered_projects (state, getters) {
        const termId = getters.get_current_term_id;
        const projects = getters.get_projects
        if(!termId){
            return projects
        }

        // console.log( 'get_filtered_projects', projects);
        // console.log( 'get_filtered_projects', termId);

        let filtered = [];
        projects.forEach((project) => {
                if(project.skills[termId] || project.context[termId]){
                    filtered.push(project)
                }

        })
        return filtered
        /*return projects.find(
            project => {
                return project.skills[termId] || project.context[termId]
            }
        )*/
    },
    get_project_main_data (state, getters) {
        const projects = getters.get_projects
        const currentProjectId = getters.get_current_project_id
        if (projects) {
            return projects.find(project => project.nid === currentProjectId)
        } else {
            return false
        }
    },
    get_project_index (state, getters) {
        const projects = getters.get_projects
        const currentProjectId = getters.get_current_project_id
        if (projects) {
            return projects.findIndex(project => project.nid === currentProjectId)
        } else {
            return false
        }
    },
    get_current_project_id (state) {
        return state.currentProjectId
    },
    shouldUpdate (state) {
        const lastFetch = state.lastFetch
        if (!lastFetch) {
            return true
        }
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - lastFetch) / 1000 > 60
    },
    getUserInfo: state => {
        return state.AppActiveUser
    },

    windowBreakPoint: state => {
        // This should be same as tailwind. So, it stays in sync with tailwind utility classes
        if (state.windowWidth >= 1200) return 'xl'
        else if (state.windowWidth >= 992) return 'lg'
        else if (state.windowWidth >= 768) return 'md'
        else if (state.windowWidth >= 576) return 'sm'
        else return 'xs'
    },

    scrollbarTag: state => {
        return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
    },

    isAuthenticated: (state) => {
        //console.log('isAuthenticated', state.AppActiveUser.idToken)
        // return  localStorage.getItem('PhedeaAccessToken')
        return true || state.AppActiveUser.idToken
        //return state.AppActiveUser.idToken
    },


    isLoading: (state) => {
        return state.isLoading
    },
    loadingStatus: (state) => {
        return state.isLoadingEnabled
    },
    isProjectModalOpen: (state) => {
      return state.isProjectModalOpen
    }
}

export default getters
