import { render, staticRenderFns } from "./ImageDisplay.vue?vue&type=template&id=29680e57&scoped=true&"
import script from "./ImageDisplay.vue?vue&type=script&lang=js&"
export * from "./ImageDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29680e57",
  null
  
)

export default component.exports