<template>
  <div class="background-info sidebar-panel" v-if="data">
    <h3 v-if="caption">{{caption}}</h3>
<!--    <h1 class="title">{{data.title}}</h1>-->
    <div class="content" v-html="data.body"></div>
    <p v-if="data.sign"><small>{{data.sign}}</small></p>
    <a class="toggleBg" href="#" @click.prevent.stop="changeBg">Cambia...</a>
  </div>
</template>

<script>
export default {
    name: "BackgroundInfo",
    props: {
        data: {required: true},
        caption: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods:{
        toggleProjectModal(){
            this.$store.commit('TOGGLE_PROJECT_MODAL');
        },
        changeBg(){
            const backgrounds = this.$store.getters.get_backgrounds
            let currentBgIndex = this.$store.getters.get_background_index
            currentBgIndex++
            if(currentBgIndex>=backgrounds.length){
                currentBgIndex=0;
            }

            this.$store.commit('setBgId',backgrounds[currentBgIndex].nid );
        }
    }
}
</script>

<style scoped>

</style>