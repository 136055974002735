<template>
  <div id="loginForm">

    <form @submit.prevent="submitForm" v-if="!isLoggedIn">
      <h1>Login</h1>

      <div class="form-control">
        <label for="email">E-Mail</label>
        <input type="email" id="email" v-model.trim="email" />
      </div>
      <div class="form-control">
        <label for="password">Password</label>
        <input type="password" id="password" v-model.trim="password" />
      </div>
      <p
              v-if="!validateForm"
      >Please enter a valid email and password (must be at least 6 characters long).</p>
      <base-button>Login</base-button>
    </form>
    <div v-else>
      Sei già loggato, {{ userInfo.userName}}
    </div>
  </div>
</template>

<script>
export default {
    name: "LoginForm",
    data() {
        return {
            email: 'guest@guest.com',
            password: 'guest',
            // email:'angelini@phedea.it',
             //password:'angelini',
            formIsValid: true,
            mode: 'login',
            isLoading: false,
            error: null,
        };
    },
    computed: {
        validateForm () {
            return  this.email !== '' && this.password !== ''
        },
        userInfo(){
            return this.$store.getters.getUserInfo
        },
        isLoggedIn() {
            //console.log('loginpage isLoggedIn ',this.$store.getters.isAuthenticated)
            return this.$store.getters.isAuthenticated
        },
    },
    methods: {

        submitForm (){
            if (this.isLoggedIn) return

            const payload = {
                email: this.email,
                password: this.password,
            };
            this.$store.dispatch('login', payload)
                .then(() => {
                   // alert('ok')
                })
                .catch(error => {
                    alert(error.message)
                })
        }
    }

}
</script>

<style scoped>

</style>