const mutations = {

  // /////////////////////////////////////////////
  // WORK
  // /////////////////////////////////////////////

  TOOGLE_IS_WORK_PAGE (state, value) {
    state.isWorkPage = value
  },
  setTermId(state, termId) {
    //console.log('setBgId', bgId)
    state.currentTermId = termId
  },
  // /////////////////////////////////////////////
  // SELECTED
  // /////////////////////////////////////////////
  SET_SELECTED (state, artworks) {
    state.selected = artworks
    // state.background = false
  },
  // /////////////////////////////////////////////
  // LOGOS
  // /////////////////////////////////////////////
  SET_LOGOS (state, logos) {
    state.logos = logos
    // state.background = false
  },
  // /////////////////////////////////////////////
  // CLIETNS
  // /////////////////////////////////////////////
  SET_CLIENTS (state, clients) {
    state.clients = clients
    // state.background = false
  },
  // /////////////////////////////////////////////
  // BG
  // /////////////////////////////////////////////
  SET_BGS (state, projects) {
    state.backgrounds = projects
   // state.background = false
  },

  setFetchBgTimestamp(state) {
    state.lastBgFetch = new Date().getTime();
  },
  setBgId(state, bgId) {
    //console.log('setBgId', bgId)
    state.isBgLoading = true
    setTimeout(function(){
      state.currentBackgroundId = bgId;
    },100)
  },
  TOOGLE_BG_ACTIVE (state, value) {
    state.isBackgroundActive = value
  },
  TOOGLE_TRANSPARENT (state, value) {
    state.isTransparent = value
  },
  TOOGLE_BG_LOADING (state, value) {
    state.isBgLoading = value
  },

  // /////////////////////////////////////////////
  // PROJECT
  // /////////////////////////////////////////////


  SET_PROJECTS (state, projects) {
    state.projects = projects
    state.project = false
  },
  CLEAR_PROJECTS (state) {
    state.projects = {}
    state.project = false
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  setProjectId(state, projectId) {
    console.log('setProjectId', projectId)
    state.currentProjectId = projectId;
  },
  IS_LOADING_ACTIVE (state, value) {
    state.isLoading = value
  },
  TOGGLE_LOADING_STATUS (state, value) {
    state.isLoadingEnabled = value
  },
  TOGGLE_PROJECT_MODAL (state) {
    state.isProjectModalOpen = !state.isProjectModalOpen
  },
  CLOSE_PROJECT_MODAL (state) {
    state.isProjectModalOpen = false
  },
  is_negative_theme (state, value){
    state.is_negative_theme = value

    const el = document.body;

    if (value) {
      el.classList.add('negative-body');
    } else {
      el.classList.remove('negative-body');
    }

  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  UPDATE_WINDOW_WIDTH (state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y (state, val) { state.scrollY           = val   },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO (state, payload) {

    // console.log('UPDATE_USER_INFO state', state);
    // console.log('UPDATE_USER_INFO', payload);


    localStorage.setItem('PhedeaAccessToken', payload.idToken)
    localStorage.setItem('PhedeaToken', payload.idToken);
    localStorage.setItem('PhedeaUserId', payload.localId);
    localStorage.setItem('PhedeaUserName', payload.userName);
    localStorage.setItem('PhedeaTokenExpiration', payload.expirationDate);

    // Get Data localStorage
    const userInfo = JSON.parse(localStorage.getItem('phedeaUserInfo')) || state.AppActiveUser


    for (const property of Object.keys(payload)) {

      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]
        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }

    //console.log('NEW', state.AppActiveUser)
    // Store data in localStorage
    localStorage.setItem('phedeaUserInfo', JSON.stringify(userInfo));
  },

  USER_LOGOUT (state){

    if (localStorage.getItem('PhedeaAccessToken')) {
      localStorage.removeItem('PhedeaAccessToken')
      localStorage.removeItem('phedeaUserInfo')
      //
    }

    // Change role on logout. Same value as initialRole of acj.js
    localStorage.removeItem('PhedeaToken');
    localStorage.removeItem('PhedeaUserId');
    localStorage.removeItem('PhedeaTokenExpiration');
    localStorage.removeItem('phedeaUserInfo')

    //console.log('USER_LOGOUT default', state.userDefaults);
    state.AppActiveUser = state.userDefaults

    // This is just for demo Purpose. If user clicks on logout -> redirect
    //this.$router.push('/login').catch(() => {})
  }
}

export default mutations

