<template>
  <nav id="main-nav" v-if="isUserLoggedIn">
    <ul>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/work">Work</router-link></li>
      <li><router-link to="/logofolio">Logofolio</router-link></li>
      <li><router-link to="/selected-artworks">Selection</router-link></li>
<!--      <li><router-link to="/skill">Skill</router-link></li>-->
<!--      <li><router-link to="/chi-siamo">Chi Siamo</router-link></li>-->
<!--      <li><router-link to="/contatti">Contatti</router-link></li>-->
    </ul>
  </nav>
</template>

<script>
export default {
    name: "NavMain",
    computed:{
        isUserLoggedIn(){
            return this.$store.getters['isAuthenticated']
        }
    }
}
</script>

<style scoped>

</style>