<template>
  <div id="app" :class="pageClasses">
    <background-static1 v-if="bg_active"></background-static1>
    <main-header :class="{'have_bg': bg_active, 'is_transparent':is_transparent}"></main-header>
    <sidebar :class="{'have_bg': bg_active, 'is_transparent':is_transparent}"></sidebar>
    <div id="main-content">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>

  </div>
</template>

<script>


import MainHeader from "@/components/MainHeader"
import Sidebar from "@/components/Sidebar"
import BackgroundStatic1 from "@/components/backgrounds/BackgroundStatic1"

export default {

    name: 'App',
    components: {BackgroundStatic1, Sidebar, MainHeader},
    data () {
        return {
           // bg_active: false
            suggestedClass:''
        }
    },
    methods:{
        updateByRoute(to){
            console.log('=============================================')
            console.log(to)
            console.log('=============================================')
            this.suggestedClass = to.name;

            if (to.name !== 'project') {
                this.$store.commit('setProjectId', false)
                this.$store.commit('CLOSE_PROJECT_MODAL')

            }

            if (to.name.startsWith("work")) {
                this.$store.commit('TOOGLE_IS_WORK_PAGE', true)
                this.suggestedClass = 'work'
                if(to.params.id){
                    this.$store.commit('setTermId', to.params.id)
                }else{
                    this.$store.commit('setTermId', false)
                }

            }else{
                this.$store.commit('TOOGLE_IS_WORK_PAGE', false)
            }


            if (to.name === 'home'  ) {
                this.$store.commit('TOOGLE_BG_ACTIVE', true)
            } else {
                this.$store.commit('TOOGLE_BG_ACTIVE', false)
            }
            if (to.name === 'selected' || to.name === 'logofolio' ) {
                this.$store.commit('TOOGLE_TRANSPARENT', true)
            } else {
                this.$store.commit('TOOGLE_TRANSPARENT', false)
            }
        }
    },
    created(){
        this.updateByRoute(this.$router.currentRoute);
    },
    watch: {
        $route (to) {
            this.updateByRoute(to);
        }
    },
    computed: {
        bg_active () {
            return this.$store.getters.get_background_is_active
        },
        is_transparent () {
            return this.$store.getters.get_is_transparent
        },
        projectId () {
            return this.$store.getters.get_current_project_id
        },
        projectData () {
            return this.$store.getters.get_project_main_data
        },
        isModalOpen () {
            return this.$store.getters.isProjectModalOpen
        },
        pageClasses () {
            const classes_array = []
            if (this.suggestedClass) classes_array.push(this.suggestedClass)
            if (this.isModalOpen) classes_array.push('no-scroll')
            if (this.projectId && this.projectData && this.projectData.theme === "negative") {
                classes_array.push('negative-theme')
                this.$store.commit('is_negative_theme', true);
            }else{
                this.$store.commit('is_negative_theme', false);
            }

            return classes_array.join(' ')
        },

    }
}
</script>

<style>
</style>
