<template>
  <section>


    <!--    <div class="_grid" v-if="projectsData">-->
    <!--      <carousel :perPage="1">-->
    <!--        <slide v-for="project in projectsData" :key="project.nid">-->
    <!--          <div :key="project.nid"  class="bg-img" :style="`background-image: url(${project.image})`"></div>-->
    <!--        </slide>-->
    <!--      </carousel>-->
    <!--    </div>-->

    <div class="page-header">
      <h1 class="big red">Logo Design & animation</h1>


    </div>
    <video-display-filename class="mb-1" :data="video"/>
    <div class="_grid" v-if="logosData && carousel_view">
      <slider ref="slider" :options="options">
        <slideritem class="slider-item-init" key="init">
          <div class="main-center bg-gray">
            <div class="text-left">
              <h1 class="big red mb-1">Logo Design</h1>
              <p class="np black">Un breve elenco dei loghi che ho ideato negli anni.<br>Potete navigare cliccando sui dots in basso.</p>
            </div>
          </div>
        </slideritem>

        <slideritem v-for="logo in logosData" :key="logo.nid">

          <div class="logo-item"
               :class="logo.bg_color ==='FFFFFF' ? 'neg':''"
               :style="'background-color: #'+logo.bg_color">
            <img :src="logo.image"/>
            <span>{{ logo.title }}</span>
          </div>

        </slideritem>
      </slider>
    </div>

    <div v-else-if="logosData">
      <p class="np">Un breve elenco dei loghi che ho ideato negli anni. Cliccate sulle anteprime per ingrandire:</p>
      <div class="grid logo-grid">

        <div
            @click="viewLogo(index)"
            class="logo-item"
            v-for="(logo, index) in logosData"
            :key="logo.nid"
            :class="logo.bg_color ==='FFFFFF' ? 'neg':''"
            :style="'background-color: #'+logo.bg_color">
          <img :src="logo.image"/>
          <span>{{ logo.title }}</span>
        </div>
      </div>

    </div>


    <transition name="fade-slow" v-else>
      <bg-loader></bg-loader>
    </transition>

    <transition name="fade">
      <div class="logoModal" :class="modalOpen?'open':'close'" v-if="logosData && modalOpen">

        <div class="logoModalBg" @click.prevent.stop="toggleModal(false)"></div>
        <slider ref="slider2" :options="options" class="modal-slider">

          <slideritem v-for="logo in logosData" :key="logo.nid">

            <div class="logo-item" :style="'background-color: #'+logo.bg_color">
              <img :src="logo.image"/>
              <span>{{ logo.title }}</span>
            </div>

          </slideritem>
        </slider>
        <button @click.prevent.stop="toggleModal(false)" class="close-button">CLOSE</button>
      </div>
    </transition>


  </section>

</template>

<script>
//import { Carousel, Slide } from 'vue-carousel';
import {slider, slideritem} from 'vue-concise-slider'

export default {
  name: "LogoFolio",
  data () {
    return {
      carousel_view: false,
      modalOpen: false,
      options: {
        currentPage: 0,
        loop: false,
        loopedSlides: 1,
        slidesToScroll: 1,
        timingFunction: 'ease-in-out',
        speed: 1000
        // effect:'fade'
      },
      video: {
        "title": "Logo Animation",
        "body": "",
        "format": "1",
        "mb": "0",
        "id": "113",
        "images": [],
        "images_sm": [],
        "video_filename": "phedea_logo_animation.mp4",
        "poster": "", "poster_sm": "", "autoplay": "1"
      }
    }
  },
  components: {
    // Carousel,
    // Slide,
    slider,
    slideritem
  },
  methods: {
    toggleModal (value = null) {
      console.log(value)
      if (value) {
        this.modalOpen = value
      } else {
        this.modalOpen = !this.modalOpen
      }
    },
    viewLogo (index) {
      if (!this.modalOpen) {
        console.log(index)
        this.options.currentPage = index
        this.$nextTick(() => {
          // this.$refs.slider2.$emit('slideTo', index)
          this.toggleModal(true)
        })
      }


    }
  },
  computed: {
    logosData () {
      return this.$store.getters.get_logos
    },

    autoplay () {
      return true
    }

  },
  watch: {
    // $route (to) {
    //     //this.$store.commit('setProjectId', to.params.id)
    //     //this.fetch_profile_data(this.profileId)
    // }
  },
  created () {
    //this.$store.commit('setProjectId', this.$route.params.id)
    //console.log('*****************************************WORKS');
    this.$store.dispatch('fetchLogos', {}).catch(err => {
      console.error(err)
    })
  }
}
</script>

<style scoped>

</style>