<template>
  <section>Not Authorized</section>
</template>

<script>
export default {
    name: "NotAuthorized"
}
</script>

<style scoped>

</style>