<template>

  <div class="video-container">
    <transition name="fade">
      <vue-plyr ref="plyr">
        <video v-if="!autoplay"
                controls
                crossorigin
                playsinline
                loop
                :data-poster="data.poster"
        >
          <source
                  size="1080"
                  :src="src"
                  type="video/mp4"
          />

        </video>
        <video v-else
               controls
               crossorigin
               playsinline
               autoplay
               :data-poster="data.poster"
        >
          <source
                  size="1080"
                  :src="src"
                  type="video/mp4"
          />

        </video>



      </vue-plyr>
    </transition>
    <transition name="fade-slow">
            <span class="loader" v-if="isLoading">
              <svg version="1.1" class="loader-svg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 1500 1000" preserveAspectRatio="none">
                    <rect x="2.5%" y="5%" class="st0" width="95%" height="90%"/>
              </svg>
            </span>
    </transition>

  </div>



</template>

<script>
import VuePlyr from 'vue-plyr'

export default {
    name: "VideoDiplayFilename",
    data () {
        return {
            isLoading: true,
            isMounted: false,
        }
    },
    component:{
        VuePlyr
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    computed:{
        src () {
           return require('../../../assets/video/'+this.data.video_filename)
        },
        autoplay () {
            return this.data.autoplay == "1" ? true : false
        },
    },
    mounted () {
        this.$refs.plyr.player.on('canplay', () => {
            this.isLoading = false
        })

    }

}
</script>

<style scoped>

</style>