<template>
  <div class="section-container section-5" v-if="data">

    <div class="d-flex">
      <div class="col">
        <section-text :data="data"></section-text>
      </div>

      <div class="col">
        <div class="img-sm-grid">
          <image-display :data="data" :index="0"></image-display>
        </div>
        <div class="image-lg-grid">
          <image-container :data="data" :index="0"></image-container>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
    name: "Section5",

    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            fakeImg: require('../../assets/visual.png')
        }
    }
}
</script>

<style scoped>

</style>