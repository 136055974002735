<template>
  <div :id="'node-'+$route.params.id" v-if="projectData" class="project">
    <!--    <section>Work ID {{projectId}} - {{projectData.title}} <router-link to="/work/project/2">goto 2</router-link></section>-->

    <section v-for="section in projectData.sections" :key="section.id" :class="section.mb === '1'?'no-mb':''">
      <component :data="section" :is="'Section'+section.format"></component>
    </section>

    <section>
      <div class="section-container tag-section">
        <div class="section-text text-center">
          <h2>I tag di questo progetto</h2>
          <div class="tag-list">
            <router-link class="tag" :to="'/work/skill/'+index" v-for="(skill,index) in projectData.skills" :key="index"><span>{{skill}}</span></router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-0">
      <div class="section-container prev-next-section">
        <div class="d-flex">
          <div class="col col-l">
            <project-teaser-nav :project="previousProject"></project-teaser-nav>
          </div>
          <div class="col col-r">
            <project-teaser-nav right :project="nextProject"></project-teaser-nav>
          </div>
        </div>
      </div>
    </section>

    <transition name="modalT">
      <project-modal v-if="isModalOpen" :data="projectData"></project-modal>
    </transition>



  </div>
</template>

<script>
import Section1 from "@/components/sections/Section1"
import Section4 from "@/components/sections/Section4"
import Section5 from "@/components/sections/Section5"
import Section6 from "@/components/sections/Section6"
import Section7 from "@/components/sections/Section7"
import Section8 from "@/components/sections/Section8"

import Section13 from "@/components/sections/Section13"
import ProjectTeaserNav from "@/components/ProjectTeaserNav"
import ProjectModal from "@/components/ProjectModal"
import Section3 from "@/components/sections/Section3"
import Section2 from "@/components/sections/Section2"
import Section14 from "@/components/sections/Section14"
import Section15 from "@/components/sections/Section15"
import Section16 from "@/components/sections/Section16"
import Section17 from "@/components/sections/Section17"

import Section9 from "@/components/sections/Section9"
import Section10 from "@/components/sections/Section10"
import Section11 from "@/components/sections/Section11"
import Section12 from "@/components/sections/Section12"
import Section10b from "@/components/sections/Section10b"


export default {
    name: "WorkDetail",
    components: {Section10b, Section12, Section11, Section10, Section9, Section16, Section15, Section14, Section2, Section3, ProjectModal, ProjectTeaserNav, Section8, Section7, Section13, Section4, Section1, Section6, Section5, Section17},
    data () {
        return {
            fakeImg: require('../assets/visual.png')
        }
    },

    watch: {
        $route (to) {
            this.$store.commit('setProjectId', to.params.id)
            //this.fetch_profile_data(this.profileId)
        }
    },
    computed: {
        projectId () {
            return this.$store.getters.get_current_project_id
        },
        projectKey () {
            return this.$store.getters.get_project_index
        },
        nextProject () {
            const lastIndex = this.projects.length - 1
            let nextIndex = this.projectKey + 1
            if (this.projectKey == lastIndex) {
                nextIndex = 0
            }
            return this.projects[nextIndex]
        },
        previousProject () {
            const lastIndex = this.projects.length - 1
            let prevIndex = this.projectKey - 1
            if (prevIndex < 0) {
                prevIndex = lastIndex
            }
            return this.projects[prevIndex]
        },
        projects () {
            return this.$store.getters.get_projects
        },
        projectData () {
            return this.$store.getters.get_project_main_data
        },
        isModalOpen () {
            return this.$store.getters.isProjectModalOpen
        }
    },
    created () {
        this.$store.commit('setProjectId', this.$route.params.id)
        this.$store.dispatch('fetchProjects', {}).catch(err => {
            console.error(err)
        })


    }
}
</script>

<style scoped lang="scss">


</style>