<template>

    <div :class="'img-container img-'+(index+1)">

      <img style="display: none" v-if="data.images[index]" :src="data.images[index]" @load="onImgLoad"/>
      <transition name="fade">
        <span v-if="!isLoading" :style="`background-image: url(${data.images[index]})`"></span>
      </transition>
      <transition name="fade-slow">
            <span class="loader" v-if="isLoading">
              <svg version="1.1" class="loader-svg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 1500 1000" preserveAspectRatio="none">
                    <rect x="2.5%" y="5%" class="st0" width="95%" height="90%"/>
              </svg>
            </span>
      </transition>

    </div>


</template>

<script>
export default {
    name: "ImageContainer",
    data () {
        return {
            isLoading: true,
            isMounted: false,
        }
    },
    methods: {
        onImgLoad () {
            setTimeout( () => {
                this.isLoading = false
            },400)
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>