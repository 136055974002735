import Vue from 'vue'

import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';

import SectionText from "@/components/sections/section-component/SectionText"
import ImageContainer from "@/components/sections/section-component/ImageContainer"
import ImageDisplay from "@/components/sections/section-component/ImageDisplay"
import VideoDisplayFilename from "@/components/sections/section-component/VideoDisplayFilename"

Vue.component('base-card', BaseCard);
Vue.component('base-button', BaseButton);
Vue.component('base-badge', BaseBadge);
Vue.component('base-spinner', BaseSpinner);
Vue.component('base-dialog', BaseDialog);

Vue.component('section-text', SectionText);
Vue.component('image-container', ImageContainer);
Vue.component('image-display', ImageDisplay);


Vue.component('video-display-filename', VideoDisplayFilename);