<template>
  <div class="bg-loader">
    <svg version="1.1" id="mySvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 3840 2160" style="enable-background:new 0 0 3840 2160;" xml:space="preserve">
          <path class="s" d="M1154.3,988.2h-148.8v184.7h30.4v-71.7h117.3c14.1,0,23.9-3.3,30.4-8.7c6.5-5.4,8.7-15.2,8.7-27.2V1024
          c0-13-3.3-21.7-8.7-27.2S1168.4,988.2,1154.3,988.2z M1161.9,1056.6c0,7.6-1.1,11.9-4.3,15.2c-3.3,3.3-7.6,4.3-15.2,4.3h-106.4
          v-64.1h105.4c7.6,0,13,1.1,15.2,4.3c3.3,3.3,4.3,7.6,4.3,15.2v25H1161.9z M1498.6,1063.1h-145.5v-74.9h-30.4v184.7h30.4v-83.6h145.5
          v83.6h30.4V988.2h-30.4V1063.1z M1709.3,1089.2h90.2v-25h-90.2v-51.1H1856v-25h-177v184.7h179.2v-25h-148.8V1089.2z M2156.8,1002.3
          c-7.6-5.4-17.4-8.7-28.2-10.9c-10.9-2.2-25-3.3-42.4-3.3h-102.1v184.7h102.1c17.4,0,31.5-1.1,42.4-3.3s20.6-5.4,28.2-10.9
          c11.9-7.6,21.7-18.5,28.2-32.6c6.5-14.1,9.8-29.3,9.8-46.7c0-16.3-3.3-31.5-9.8-44.5C2178.6,1020.8,2168.8,1009.9,2156.8,1002.3z
          M2144.9,1131.6c-11.9,10.9-33.7,15.2-63,15.2h-66.3v-133.6h66.3c29.3,0,50,5.4,63,15.2c13,9.8,18.5,27.2,18.5,51.1
          S2157.9,1120.7,2144.9,1131.6z M2357.8,1089.2h90.2v-25h-90.2v-51.1h146.6v-25h-178.1v184.7h179.2v-25h-147.7V1089.2z M2731.4,988.2
          h-32.6l-101,184.7h30.4l23.9-45.6H2776l23.9,45.6h33.7L2731.4,988.2z M2664.1,1103.3l50-94.5l50,94.5H2664.1z" id="bg-loader-path"></path>
      </svg>
  </div>
</template>

<script>
export default {
    name: "bgLoader"
}
</script>

<style scoped>

</style>