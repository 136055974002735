<template>
  <section>
    <div class="page-header">
      <h1 class="big red">Work</h1>
      <h2 v-if="!currentTermId">Ultimi progetti</h2>
      <h2 v-else>Progetti per: <strong>{{currentTermName}}</strong></h2>
    </div>

    <div class="grid" v-if="projectsData">
      <transition-group class="grid-row" name="ty">
          <project-teaser :key="project.nid" v-for="(project, index) in projectsData" :index="index" :project="project"></project-teaser>
      </transition-group>
    </div>
    <transition name="fade-slow" v-else>
      <bg-loader></bg-loader>
    </transition>
  </section>

</template>

<script>
import ProjectTeaser from "@/components/ProjectTeaser"
export default {
    name: "WorkList",
    components: {ProjectTeaser},

    computed: {
        projectsData () {
            return this.$store.getters.get_filtered_projects
        },

        currentTermId () {
            return this.$store.getters.get_current_term_id
        },
        currentTermName () {
            console.log(this.currentTermId);
            if(this.currentTermId){
                const terms = this.$store.state.extractTags( this.$store.getters.get_projects ,false, true);
                console.log(terms);
                return terms[this.currentTermId] ? terms[this.currentTermId] : false
            }else{
                return false;
            }
        }
    },
    watch: {
        // $route (to) {
        //     //this.$store.commit('setProjectId', to.params.id)
        //     //this.fetch_profile_data(this.profileId)
        // }
    },
    created () {
        //this.$store.commit('setProjectId', this.$route.params.id)
        //console.log('*****************************************WORKS');
        this.$store.dispatch('fetchProjects',{}).catch(err => {
            console.error(err)
        })
    }
}
</script>

<style scoped>

</style>