<template>
  <div class="section-container section-1" v-if="data">

    <section-text :data="data"></section-text>

    <div class="img-sm-grid" v-if="data.images_sm.length">
      <image-display :data="data" :index="0"></image-display>
    </div>
    <div class="image-lg-grid" v-if="data.images.length">
      <image-container :data="data" :index="0"></image-container>
    </div>

    <video-display-filename v-if="isVideoExits" :data="data"></video-display-filename>


  </div>
</template>

<script>


export default {
    name: "Section1",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            fakeImg: require('../../assets/visual.png'),
           // src: require('../../assets/en_1080_0608.mp4')
        }
    },
    computed:{
        isVideoExits () {
            let response = false;
            if(this.data.video_filename){
                response = true;
                if(!require('../../assets/video/'+this.data.video_filename)){
                    response = false;
                }
            }

            return response;


        },
        autoplay () {
            return this.data.autoplay == "1" ? true : false
        },
    },
}
</script>

<style scoped>

</style>