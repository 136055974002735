<template>
  <nav id="user-nav">
    <ul>
      <li v-if="!isLoggedIn"><router-link to="/login">Login</router-link></li>
      <li v-if="isLoggedIn"><a href="#" @click.prevent="logOut">Logout</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
    name: "NavUser",
    computed:{
        isLoggedIn() {
            return this.$store.getters.isAuthenticated
        }
    },
    methods:{
        logOut(){
            this.$store.dispatch('logout');
        },
    },
}
</script>

<style scoped>

</style>