<template>
  <section class="mb-0">
    <div class="main-center">
      <div class="about-row">
        <div class="about-col about-col-1">
          <h1 class="big red mb-1 pt-0">Storia</h1>
          <p>Mi chiamo <strong>Federico Romanazzo</strong> e lavoro come graphic designer dai primi anni '90.
            Ho operato sempre come freelance, collaborando con numerori, stimati professionisti e agenzie di comunicazione
            sia in qualità di designer che come direttore artistico.</p>
          <p>Nel campo della comunicazione visiva ho maturato esperienze in tutti i settori: dalla brand identity alla carta stampata, dalla motion graphics 2D e 3D
            fino alla progettazione e allo sviluppo di ogni tipo di applicazione digitale.</p>
          <p><router-link to="/work">Scoprite alcuni miei recenti progetti organizzati per skill</router-link></p>
          <p><strong>Desiderate contattarmi?</strong></p>
          <p>Potete scrivermi a: <a href="mailto:federico.romanazzo@gmail.com">federico.romanazzo@gmail.com</a></p>
        </div>
        <div class="about-col about-col-2">
          <h2>Alcuni tra i clienti con cui ho avuto il piacere di lavorare:</h2>
          <div class="client-list" v-if="clients">
            <div class="client-item" v-for="client in clients" :key="client.order">
              <img :src="client.logo"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "AboutUs",
  computed: {
    clients () {
      return this.$store.getters.get_clients
    },
  },
  created () {
    //this.$store.commit('setProjectId', this.$route.params.id)
    //console.log('*****************************************WORKS');
    this.$store.dispatch('fetchClients', {}).catch(err => {
      console.error(err)
    })
  }
}
</script>

<style scoped>

</style>