<template>
  <div id="mobile-nav">
   <nav-main></nav-main>
    <nav-user></nav-user>
  </div>
</template>

<script>
import NavMain from "@/components/ui/NavMain"
import NavUser from "@/components/ui/NavUser"
export default {
    name: "NavMobile",
    components: {NavUser, NavMain},

}
</script>

<style scoped>

</style>