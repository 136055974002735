import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// Vuex Store
import store from './store/store.js'

// Globally Registered Components
import './globalComponents.js'


// VUE carousel
// import VueCarousel from 'vue-carousel';
// Vue.use(VueCarousel);

// VuePlyr
import VuePlyr from 'vue-plyr'
//import 'vue-plyr/dist/vue-plyr.css'
//import './assets/scss/plyr/plyr.scss'


// Styles: SCSS
import '@/assets/scss/style.scss'

Vue.config.productionTip = false
Vue.use(VuePlyr)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')