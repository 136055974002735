<template>
  <div class="filter-list sidebar-panel">
    <div class="content">
      <h3>
        <a href="#" @click.prevent.stop="toggleFilters">Filtra i progetti</a>
      </h3>

      <div :class="{'filter-block collapse': true, 'show': filterOpened}">
        <div class="filter-blocks">
          <div v-if="skills" class="filter-group" key="skills">
            <h2>Skills</h2>
            <ul>
              <li :class="{'active': skill.id === currentTermId }" :key="skill.id" v-for="(skill) in skills">
                <router-link v-if="skill.id === currentTermId" to="/work"><span> {{skill.name}}</span></router-link>
                <router-link v-else :to="'/work/skill/'+skill.id"><span> {{skill.name}}</span></router-link>
              </li>
            </ul>

          </div>

          <div v-if="context && false" class="filter-group" key="context">
            <h2>Context</h2>
            <ul>
              <li :key="s_context.id" v-for="(s_context) in context">
                <router-link :to="'/work/context/'+s_context.id"><span> {{s_context.name}}</span></router-link>
              </li>
            </ul>
          </div>
        </div>

      </div>




    </div>
  </div>
</template>

<script>
export default {
    name: "FilterList",
    data(){
        return {
            filterOpened: false
        }
    },
    methods:{
        toggleFilters(){
            this.filterOpened = !this.filterOpened
        }
    },
    computed: {
        projects () {
            return this.$store.state.projects
        },
        currentTermId () {
            return this.$store.getters.get_current_term_id
        },
        skills () {
            return this.$store.state.extractTags(this.projects, 'skills')
        },
        context () {
            return this.$store.state.extractTags(this.projects, 'context')
        }
    }
}
</script>

<style scoped>

</style>