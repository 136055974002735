import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Homepage from "@/pages/Homepage"
import Login from "@/pages/Login"
import NotFound from "@/pages/NotFound"
import NotAuthorized from "@/pages/NotAuthorized"
import WorkList from "@/pages/WorkList"
import WorkDetail from "@/pages/WorkDetail"
import AboutUs from "@/pages/AboutUs"
import Contact from "@/pages/Contact"
import Skills from "@/pages/Skills"
import store from './store/store.js'
import SelectedArtwork from "@/pages/SelectedArtwork"
import LogoFolio from "@/pages/LogoFolio"


const routerConfig = {
    //mode: 'history',
    //base: process.env.BASE_URL,
    scrollBehavior () {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', name: 'home', component: Homepage},

        {path: '/work', name: 'work', component: WorkList, meta: {requiresAuth: true}},
        {path: '/work/skill/:id', name: 'work-skill', component: WorkList, meta: {requiresAuth: true}},
        {path: '/work/context/:id', name: 'work-context', component: WorkList, meta: {requiresAuth: true}},

        {path: '/work/project/:id', name: 'project', component: WorkDetail, meta: {requiresAuth: true}},

        {path: '/selected-artworks', name: 'selected', component: SelectedArtwork},

        {path: '/logofolio', name: 'logofolio', component: LogoFolio},

        {path: '/about', name: 'about', component: AboutUs},
        {path: '/skills', name: 'skills', component: Skills},
        {path: '/contatti', name: 'contact', component: Contact},



        {path: '/login', name: 'login', component: Login},
        {path: '/not-authorized', component: NotAuthorized},

        {path: '/:notFound(.*)', component: NotFound}
    ]
}
const router = new Router(routerConfig)

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next({name: 'login', query: {to: to}})
    } else {
        next()
    }

})

export default router