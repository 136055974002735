<template>

      <div :class="teaserClass" v-if="project">
        <router-link :to="'/work/project/'+project.nid">
          <img style="display: none" v-if="project.thumb" :src="project.thumb" @load="onImgLoad"/>



          <span class="img-container">
            <transition name="fade">
              <span  v-if="!isLoading" :style="`background-image: url(${project.thumb})`">
                 <on-progress-badge v-if="project.on_progress"></on-progress-badge>
              </span>
            </transition>
            <!--<img :src="fakeImg">-->
            <transition name="fade-slow">
            <span class="loader" v-if="isLoading">
              <svg version="1.1" class="loader-svg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 150 100" preserveAspectRatio="none">
              <rect x="2.5%" y="5%" class="st0" width="95%" height="90%"/>
              </svg>
            </span>
            </transition>
          </span>

          <span class="teaser-text">
            <h3>
               {{project.title}}
            </h3>
          </span>
        </router-link>
      </div>

</template>

<script>
import OnProgressBadge from "@/components/ui/OnProgressBadge"
export default {
    name: "ProjectTeaser",
    components: {OnProgressBadge},
    props: {
        project: {required: true},
        index: {required: true}
    },
    data () {
        return {
            isLoading: true,
            isMounted: false,
        }
    },
    methods: {
        onImgLoad () {
            setTimeout( () => {
                this.isLoading = false
            },400)
        }
    },
    computed:{
        teaserClass(){
            return this.isMounted ? 'teaser teaser-to' : 'teaser teaser-from'
        }
    },
    mounted () {
        setTimeout( () => {
            this.isMounted = true;
        }, 200+(100*this.index) )
    }

}
</script>

<style scoped>

</style>