import { render, staticRenderFns } from "./Section6.vue?vue&type=template&id=8af5b6ba&scoped=true&"
import script from "./Section6.vue?vue&type=script&lang=js&"
export * from "./Section6.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8af5b6ba",
  null
  
)

export default component.exports