<template>
  <div class="bg-container">

    <img style="display: none" v-if="bgImg" :src="bgImg" @load="onImgLoad"/>
    <transition name="fade" mode="out-in">
      <div :key="currentBgId" v-if="bgImg && !isLoading" class="bg-img" :style="`background-image: url(${bgImg})`"></div>
    </transition>
    <transition name="fade-slow">
      <bg-loader v-if="isLoading" :class="{'started': isLoading}"></bg-loader>
    </transition>
  </div>


</template>

<script>
import BgLoader from "@/components/backgrounds/bgLoader"
export default {
    name: "BackgroundStatic1",
    components: {BgLoader},
    data () {
        return {
            startingPoint: 0,
            max: 0,
            //isLoading: this.$store.getters.get_background_is_loading,
            loaded: false,
        }
    },
    methods:{
        onImgLoad () {
            this.$store.commit('TOOGLE_BG_LOADING', false)
            this.loaded = true
        },
    },
    computed: {
        isLoading () {
            //return true
            return this.$store.getters.get_background_is_loading
        },
        backgrounds () {
            return this.$store.getters.get_backgrounds
        },
        currentBg () {
            //console.log('currentBg', this.$store.getters.get_background_data)
            return this.$store.getters.get_background_data
        },
        currentBgId () {
            //console.log('currentBg', this.$store.getters.get_background_data)
            return this.$store.getters.get_background_index
        },


        bgImg () {

            //console.log('bgImg currentBg', this.currentBg)
            if (this.currentBg) {
                const image = this.currentBg.image
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                return image
            } else {
                return ''
            }

        }
    },
    created () {

        this.$store.commit('TOOGLE_BG_LOADING', true)
        this.$store.dispatch('fetchBackgrounds', {})
            .then(res => {
                console.log(res)
                this.max = res.length - 1
                const n = Math.floor((Math.random() * this.max) + 1)
                this.startingPoint = n
                this.$store.commit('setBgId', res[n].nid)
            })
            .catch(err => {
                console.error(err)
            })
    }

}
</script>

<style scoped>


</style>