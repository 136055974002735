<template>
  <div class="feature-list">
      <h3 v-if="title">{{title}}</h3>
    <ul>
      <li class="feature" v-for="(item,index) in list" :key="index">

        <router-link v-if="prefix" :to="'/work/'+prefix+'/'+index"><span>{{item}}</span></router-link>
        <span v-else>{{item}}</span>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
    name: "featureList",
    props: {
        list:{
            type: Object,
            required: true
        },
        title:{
            type: String,
            required: false
        },
        prefix:{
            type: String,
            required: false
        },
    },
}
</script>

<style scoped>

</style>