<template>
  <img :class="'img-display img-'+(index+1)" :src="data.images_sm[index]"/>
</template>

<script>
export default {
    name: "ImageDisplay",
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>