<template>

  <svg version="1.1" class="on-progress-badge"
       x="0px" y="0px"
       viewBox="0 0 283 160"
       xml:space="preserve">

      <defs>
	<filter id="opb_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="20.2" height="160">
       <feColorMatrix
                    type="matrix"
                    values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0"
                    color-interpolation-filters="sRGB"
                    result="source"/>
      <feFlood
               style="flood-color:white;flood-opacity:1"
               result="back"/>
      <feBlend
              in="source"
              in2="back"
              mode="normal"/>
	</filter>
</defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="20.2" height="160" id="SVGID_1_">
      <g class="opb-text-container">
          <text transform="matrix(6.123234e-17 1 -1 6.123234e-17 4 6.2471)" class="opb-text">ON PROGRESS</text>
      </g>
</mask>
    <rect class="opb-rect" width="16" height="110"/>
</svg>

</template>

<script>
export default {
    name: "OnProgressBadge"
}
</script>

<style scoped>

</style>