<template>
  <div class="modal project-modal">
      <div class="modal-content">

        <div class="col">
          <project-info v-if="data" :data="data" class="modal-header"></project-info>
          <div class="modal-body" v-html="data.body"></div>
        </div>
        <div class="col">
          <feature-list title="Cliente" :list="data.client"></feature-list>
          <feature-list title="Context" :list="data.context" prefix="context"></feature-list>
          <feature-list title="Skills" :list="data.skills" prefix="skills"></feature-list>
          <feature-list title="Tools" :list="data.tools"></feature-list>
        </div>

      </div>
      <a class="close-button" href="#" @click.prevent.stop="toggleProjectModal"><svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="49.3536" y1="0.353553" x2="1.35356" y2="48.3535" stroke="#D60000"/>
        <line x1="48.6464" y1="48.3536" x2="0.646454" y2="0.353552" stroke="#D60000"/>
      </svg>
      </a>
  </div>
</template>

<script>
import FeatureList from "@/components/ui/featureList"
import ProjectInfo from "@/components/sidebar/ProjectInfo"

export default {
    name: "ProjectModal",
    components: {ProjectInfo, FeatureList},
    props: {
        data:{
            type: Object,
            required: true
        }
    },
    methods: {
        toggleProjectModal(){
            this.$store.commit('TOGGLE_PROJECT_MODAL');
        },
        closeModal(){
            this.$emit('close-modal');
        }
    }
}
</script>

<style scoped>

</style>