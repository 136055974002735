import router from '@/router'
import axios from '@/axios.js'

let timer;

const actions = {

    fetchProjects(context, payload){

        const isUserLogged = context.getters.isAuthenticated;
        if(!isUserLogged) return;

        const cached = context.getters.get_projects;
        if (cached.length && !payload.forceRefresh && !context.getters.shouldUpdate) {
            return context.getters.get_projects;
        }else{
            const profile =  context.getters.getUserInfo;
            return new Promise((resolve, reject) => {
                console.log('##### fetchProjects',{profile})
                axios.post('/api/fr-api-project-list',{profile})
                    .then((response) => {
                        //console.(response)
                        context.commit('SET_PROJECTS', response.data)
                        context.commit('setFetchTimestamp')

                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }

    },
    fetchBackgrounds(context, payload){

        if (!payload.forceRefresh && !context.getters.shouldBgUpdate) {
            return context.getters.get_backgrounds;
        }
        return new Promise((resolve, reject) => {
            console.log('##### fetchBackgrounds')
            axios.post('/api/fr-api-bg-list')
                .then((response) => {
                    //console.(response)
                    context.commit('SET_BGS', response.data)
                    context.commit('setFetchBgTimestamp')

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchSelected(context, payload){

        if (!payload.forceRefresh ) {
            //return context.getters.get_selected;
        }
        return new Promise((resolve, reject) => {
            console.log('##### fetchSelected')
            axios.post('/api/fr-api-selected-list')
                .then((response) => {
                    //console.(response)
                    context.commit('SET_SELECTED', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchLogos(context, payload){

        if (!payload.forceRefresh ) {
            //return context.getters.get_selected;
        }
        return new Promise((resolve, reject) => {
            console.log('##### fetchLogos')
            axios.post('/api/fr-api-logo-list')
                .then((response) => {
                    //console.(response)
                    context.commit('SET_LOGOS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchClients(context, payload){

        if (!payload.forceRefresh ) {
            //return context.getters.get_selected;
        }
        return new Promise((resolve, reject) => {
            console.log('##### fetchClients')
            axios.post('/api/fr-api-client-list')
                .then((response) => {
                    //console.(response)
                    context.commit('SET_CLIENTS', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    login( context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/fr-api-login`, payload)
                .then((response) => {
                    const responseData = response.data;
                    if ( response.data.error) {
                        const error = new Error(
                            response.data.message || 'Failed to authenticate. Check your login data.'
                        );
                        throw error;
                    }

                    const expiresIn = responseData.expiresIn * 1000;
                    responseData.expirationDate = new Date().getTime() + expiresIn;
                    context.commit('UPDATE_USER_INFO',responseData)
                    timer = setTimeout(function() {
                        context.dispatch('autoLogout');
                    }, expiresIn);
                    router.push(router.currentRoute.query.to || '/work')
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    logout(context) {
        console.log('logout');
        context.commit('USER_LOGOUT');
        context.commit('CLEAR_PROJECTS')
        clearTimeout(timer);
        router.push('/login')
    },
    autoLogout(context) {
        console.log('autoLogout');
        context.dispatch('logout');
        context.commit('setAutoLogout');
    }

}

export default actions
