// axios
import axios from 'axios'

const baseURL = 'https://www.stage72.info/phedea-dev/data/'
//const baseURL = 'http://feroma.lab/phedea-data2/'


export default axios.create({
    baseURL,
   // timeout: 100  // use this custom option to allow overrides
})
