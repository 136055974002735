<template>
  <div class="section-container section-4">
    <div class="img-sm-grid">
      <image-display :data="data" :index="0"></image-display>
      <section-text :data="data"></section-text>
    </div>
    <div class="image-lg-grid">
      <image-container :data="data" :index="0"></image-container>
      <section-text :data="data"></section-text>
    </div>
  </div>
</template>

<script>

export default {
    name: "Section4",

    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            fakeImg: require('../../assets/visual.png')
        }
    }
}
</script>

<style scoped>

</style>