<template>
  <section>Contact</section>
</template>

<script>
export default {
    name: "Contact"
}
</script>

<style scoped>

</style>