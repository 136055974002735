<template>
  <aside id="sidebar">
    <div class="sidebar-container">
<!--      <transition name="tx" mode="out-in">-->
<!--        <project-info v-if="projectId && projectData" :data="projectData" caption="Project Info"></project-info>-->
<!--      </transition>-->
<!--      <transition name="tx" mode="out-in">-->
<!--        <background-info v-if="bg_active && backgroundId && backgroundData && !isLoading" :data="backgroundData"></background-info>-->
<!--      </transition>-->
<!--      <transition name="ty" mode="out-in">-->
<!--        <filter-list v-if="isWorkPage"></filter-list>-->
<!--      </transition>-->

      <transition name="tx" mode="out-in">
        <project-info v-if="projectId && projectData" :data="projectData" caption="Project Info"></project-info>
        <background-info v-else-if="bg_active && backgroundId && backgroundData && !isLoading" :data="backgroundData"></background-info>
        <filter-list v-else-if="isWorkPage"></filter-list>
      </transition>

    </div>
  </aside>
</template>

<script>
import ProjectInfo from "@/components/sidebar/ProjectInfo"
import BackgroundInfo from "@/components/sidebar/BackgroundInfo"
import FilterList from "@/components/sidebar/FilterList"
export default {
    name: "Sidebar",
    components: {FilterList, BackgroundInfo, ProjectInfo},
    computed: {
        isWorkPage () {
            return this.$store.getters.get_is_work_page
        },
        bg_active () {
            return this.$store.getters.get_background_is_active
        },
        isLoading () {
            return this.$store.getters.get_background_is_loading
        },
        projectId(){
            return this.$store.getters.get_current_project_id
        },
        projectData () {
            return this.$store.getters.get_project_main_data
        },

        backgroundId(){
            return this.$store.getters.get_current_background_id
        },
        backgroundData () {
            return this.$store.getters.get_background_data
        },
    },
}
</script>

<style scoped>

</style>