
// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider

const userDefaults = {
  localId: 0,
  userName: "",
  userMail: "",
  expiresIn: "",
  idToken: false,
}
const userInfoLocalStorage = JSON.parse(localStorage.getItem('phedeaUserInfo')) || {}

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {

  const userInfo = {}

  //console.log('userInfoLocalStorage', userInfoLocalStorage)
  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key]
  })

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

const getUserToken = () => {
  //const isAuthenticated = false
  return  localStorage.getItem('PhedeaAccessToken')
}
// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}



// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: getUserInfo(),
  userDefaults: userDefaults,
  is_touch_device: is_touch_device(),
  idToken: getUserToken(),
  scrollY: 0,

  isLoading: false,
  isLoadingEnabled: false,
  isProjectModalOpen: false,

  windowWidth: null,

  isWorkPage: false,
  currentTermId: false,
  currentTermName: false,

  projects: [],

  currentProjectId: false,
  lastFetch: null,
  is_negative_theme: false,


  logos: [],
  clients: [],

  backgrounds: [],
  isBackgroundActive: false,
  isTransparent:true,
  currentBackgroundId: false,
  lastBgFetch: null,
  isBgLoading: false,


  selected: [],

  isUserLoggedIn: () => {
    //console.log(localStorage.getItem('PhedeaAccessToken'));
    //const isAuthenticated = false
    return localStorage.getItem('PhedeaAccessToken')
  },
  extractTags: (list, tagName, onlyObj = false) => {
    //estraggo
    let terms_object = {};
    if(tagName){
      list.forEach((item) => {
        terms_object = {...terms_object, ...item[tagName]}
      })
    }else{
      list.forEach((item) => {
        terms_object = {...terms_object, ...item['skills'], ...item['context'], ...item['tools'], ...item['client']}
      })
    }
    if(onlyObj){
      return  terms_object;
    }


    //pongo in array
    let terms = [];
    for (const [id, name] of Object.entries(terms_object)) {
      //console.log(`${key}: ${value}`);
      terms.push({id, name})
    }


    //sort alfabetico

    // terms.sort(function (a, b) {
    //     return a.name - b.name;
    // });

    terms.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignora maiuscole e minuscole
      var nameB = b.name.toUpperCase(); // ignora maiuscole e minuscole
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // i nomi devono essere uguali
      return 0;
    });

    return terms;
  }
}



export default state
