<template>
  <section>


<!--    <div class="_grid" v-if="projectsData">-->
<!--      <carousel :perPage="1">-->
<!--        <slide v-for="project in projectsData" :key="project.nid">-->
<!--          <div :key="project.nid"  class="bg-img" :style="`background-image: url(${project.image})`"></div>-->
<!--        </slide>-->
<!--      </carousel>-->
<!--    </div>-->
    <div class="_grid" v-if="projectsData">
      <slider ref="slider" :options="options">
        <slideritem class="slider-item-init" key="init">
          <div class="main-center bg-gray">
            <div class="text-left">
              <h1 class="big red mb-1">Alcuni tra i miei lavori preferiti</h1>
              <p class="np">Vi propongo una selezione di visual ai quali sono particolarmento legato.<br>Potete navigare cliccando sui dots in basso.</p>
            </div>
          </div>
        </slideritem>

        <slideritem  v-for="project in projectsData" :key="project.nid">

          <video-display-filename v-if="isVideoExits(project)" :data="project"></video-display-filename>
          <div :key="project.nid"  class="bg-img" :style="`background-image: url(${project.image})`"></div>


        </slideritem>
      </slider>
    </div>
    <transition name="fade-slow" v-else>
      <bg-loader></bg-loader>
    </transition>
  </section>

</template>

<script>
//import { Carousel, Slide } from 'vue-carousel';
import { slider, slideritem } from 'vue-concise-slider'
export default {
    name: "SelectedArtwork",
    data () {
        return {
            options: {
                currentPage: 0,
                loop:false,
                loopedSlides:1,
                slidesToScroll:1,
                timingFunction: 'ease-in-out',
                speed:1000
               // effect:'fade'
            }
        }
    },
    components: {
        // Carousel,
        // Slide,
        slider,
        slideritem
    },
    methods:{
        isVideoExits (data) {
            let response = false;
            if(data.video_filename){
                response = true;
                if(!require('../assets/video/'+data.video_filename)){
                    response = false;
                }
            }

            return response;


        },
    },
    computed: {
        projectsData () {
            return this.$store.getters.get_selected
        },

        autoplay () {
            return true
        },

    },
    watch: {
        // $route (to) {
        //     //this.$store.commit('setProjectId', to.params.id)
        //     //this.fetch_profile_data(this.profileId)
        // }
    },
    created () {
        //this.$store.commit('setProjectId', this.$route.params.id)
        //console.log('*****************************************WORKS');
        this.$store.dispatch('fetchSelected',{}).catch(err => {
            console.error(err)
        })
    }
}
</script>

<style scoped>

</style>