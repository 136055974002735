<template>
  <div class="project-info sidebar-panel" v-if="data">
    <h3 v-if="caption">
      <a href="#" @click.prevent.stop="toggleProjectModal"> {{caption}}</a>
    </h3>
    <div class="project-info-block collapse">
      <h1 class="title">{{data.title}}</h1>
      <div class="content" v-html="data.abstract"></div>
      <a class="toggleModal" href="#" @click.prevent.stop="toggleProjectModal">Dettagli...</a>
    </div>

  </div>
</template>

<script>
export default {
    name: "ProjectInfo",
    props: {
        data: {required: true},
        caption: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods:{
        toggleProjectModal(){
            this.$store.commit('TOGGLE_PROJECT_MODAL');
        }
    }
}
</script>

<style scoped>

</style>