<template>
  <div class="section-container section-10" v-if="data">

    <div class="d-flex d-flex-sm">


      <div class="col col-half">
        <div class="img-sm-grid">
          <image-display :data="data" :index="0"></image-display>
        </div>
        <div class="image-lg-grid">
          <image-container :data="data" :index="0"></image-container>
        </div>
      </div>

      <div class="col col-half">
        <div class="img-sm-grid">
          <image-display :data="data" :index="1"></image-display>
        </div>
        <div class="image-lg-grid">
          <image-container :data="data" :index="1"></image-container>
        </div>
      </div>

      <div class="col">
        <section-text :data="data"></section-text>
      </div>

    </div>


  </div>
</template>

<script>


export default {
    name: "Section10",

    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            fakeImg: require('../../assets/visual.png')
        }
    }
}
</script>

<style scoped>

</style>