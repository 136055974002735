<template>
  <header>
    <div class="header-container">
      <div class="logo"><router-link to="/">
        <svg width="169" height="17" viewBox="0 0 169 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.05 0H0.349976V17H3.14998V10.4H13.95C15.25 10.4 16.15 10.1 16.75 9.6C17.35 9.1 17.55 8.2 17.55 7.1V3.3C17.55 2.1 17.25 1.3 16.75 0.8C16.25 0.3 15.35 0 14.05 0ZM14.75 6.3C14.75 7 14.65 7.4 14.35 7.7C14.05 8 13.65 8.1 12.95 8.1H3.14998V2.2H12.85C13.55 2.2 14.05 2.3 14.25 2.6C14.55 2.9 14.65 3.3 14.65 4V6.3H14.75ZM45.75 6.9H32.35V0H29.55V17H32.35V9.3H45.75V17H48.55V0H45.75V6.9ZM65.15 9.3H73.45V7H65.15V2.3H78.65V0H62.35V17H78.85V14.7H65.15V9.3ZM106.35 1.3C105.65 0.8 104.75 0.5 103.75 0.3C102.75 0.1 101.45 0 99.85 0H90.45V17H99.85C101.45 17 102.75 16.9 103.75 16.7C104.75 16.5 105.65 16.2 106.35 15.7C107.45 15 108.35 14 108.95 12.7C109.55 11.4 109.85 10 109.85 8.4C109.85 6.9 109.55 5.5 108.95 4.3C108.35 3 107.45 2 106.35 1.3ZM105.25 13.2C104.15 14.2 102.15 14.6 99.45 14.6H93.35V2.3H99.45C102.15 2.3 104.05 2.8 105.25 3.7C106.45 4.6 106.95 6.2 106.95 8.4C106.95 10.6 106.45 12.2 105.25 13.2ZM124.85 9.3H133.15V7H124.85V2.3H138.35V0H121.95V17H138.45V14.7H124.85V9.3ZM159.25 0H156.25L146.95 17H149.75L151.95 12.8H163.35L165.55 17H168.65L159.25 0ZM153.05 10.6L157.65 1.9L162.25 10.6H153.05Z"/>
        </svg>
      </router-link></div>
      <nav-main></nav-main>
<!--      <nav-user></nav-user>-->

      <a href="#" class="menuToggle" @click.prevent.stop="toggleMenu">MENU</a>

    </div>
    <nav-mobile v-if="menuOpen"></nav-mobile>
  </header>
</template>

<script>
import NavMain from "@/components/ui/NavMain"
//import NavUser from "@/components/ui/NavUser"
import NavMobile from "@/components/ui/NavMobile"
export default {
    name: "MainHeader",
    components: {NavMobile, NavMain},
    data(){
        return{
          menuOpen:false
        }
    },
    watch: {
        $route () {
            this.menuOpen=false
        }
    },
    methods:{
        logOut(){
            this.$store.dispatch('logout');
        },
        toggleMenu(){
            this.menuOpen = !this.menuOpen
        }

    },
    computed:{
        isLoggedIn() {
            return this.$store.getters.isAuthenticated
        }
    }
}
</script>

<style scoped>

</style>