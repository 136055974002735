<template>
  <div class="section-text" v-if="data.title || data.body">
    <h2 v-if="data.title">{{data.title}}</h2>
    <div class="par" v-if="data.body" v-html="data.body"></div>
  </div>
</template>

<script>
export default {
    name: "SectionText",
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>